/*** RESET CSS ***/

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}
/* HTML5 display-role reset for older browsers */

html {
	line-height: 1.7;
	margin-bottom: 50px;
	border: 0;
	font-size: 100%;
	font-family: 'Montserrat', sans-serif;
	font: inherit;
	vertical-align: baseline;
}

body {
	box-sizing: border-box;
}

ol,
ul {
	list-style: none;
}

/*** APP CSS ***/

.hero-image {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(15, 32, 39, 0.6)), url('/img/fca_bg.png');
	background-size: cover;
	background-position: center;
	height: 85vh;
}

.right {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.right a {
	outline: 0;
}

/*** Nomination Card ***/
.card_container {
	height: 200px;
	width: 380px;
	border-radius: 10px;
	margin: 15px 10px;
}

/*** Auth Background ***/

.auth_bg {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(15, 32, 39, 0.6)), url('/img/cape_town_stadium.jpg');
	background-size: cover;
	background-position: center;
	height: 100vh;
	width: 100%;
	padding-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

/*** About Hero ***/
.about-hero,
.contact-hero {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(15, 32, 39, 0.6)), url('/img/cape_town_stadium01.png');
	background-size: cover;
	background-position: center;
	width: 96%;
	height: 35vh;
	margin: 20px auto;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

	display: flex;
	justify-content: space-between;
	align-items: center;
}

/*** Contact Page **/
.contact-hero {
}
